import React from 'react';
import { withTrans } from '../i18n/withTrans';
import Layout from '../components/Layout/Layout';
import { Helmet } from 'react-helmet';
import Favicon from '../assets/images/favicon-manatour-16x16.png';
import Collapse from '../components/RGPD/Collapse';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { navigate } from 'gatsby';

const RGPD = ({ t }) => {
  const listProtectDataC = ['title1', 'title2', 'title3', 'title4'];
  const listProtectDataD = ['title1', 'title2', 'title3', 'title4'];
  const listProtectDataG = ['title1', 'title2', 'title3'];
  return (
    <Layout pageName="rgpd">
      <Helmet>
        <title>{t('helmet.reservation_page')}</title>
        <link
          rel="icon"
          type="image/png"
          href={Favicon}
          sizes="16x16"
          className="favicon"
        />
      </Helmet>
      <div className="rgpd">
        <h1 className="h1-rgpd" onClick={() => navigate(-1)}>
          <AiOutlineArrowLeft className="title-arrow" />
          {t('rgpd.title')}
        </h1>
        <p>{t('rgpd.description')}</p>
        <h2>{t('rgpd.title1')}</h2>
        <p style={{ whiteSpace: 'pre-line' }}>{t('rgpd.protectData1')}</p>
        <Collapse title="protectDataSubTitle1" body="protectDataADescription" />
        <Collapse title="protectDataSubTitle2" body="protectDataBDescription" />
        <Collapse
          title="protectDataSubTitle3"
          body="protectDataCDescription"
          list
          data={listProtectDataC}
        />
        <Collapse
          title="protectDataSubTitle4"
          body="protectDataDDescription"
          list
          data={listProtectDataD}
        />
        <Collapse title="protectDataSubTitle5" body="protectDataEDescription" />
        <Collapse title="protectDataSubTitle6" body="protectDataFDescription" />
        <Collapse
          title="protectDataSubTitle7"
          body="protectDataGDescription"
          list
          data={listProtectDataG}
        />
        <Collapse
          title="protectDataSubTitle8"
          body="protectDataHDescription"
          list
          data={listProtectDataG}
        />
        <Collapse title="protectDataSubTitle9" body="protectDataIDescription" />
        <Collapse
          title="protectDataSubTitle10"
          body="protectDataJDescription"
          list
          data={listProtectDataC}
        />
        <Collapse
          title="protectDataSubTitle11"
          body="protectDataKDescription"
        />
        <Collapse
          title="protectDataSubTitle12"
          body="protectDataLDescription"
        />
        <h2>{t('rgpd.title2')}</h2>
        <p style={{ whiteSpace: 'pre-line' }}>{t('rgpd.personAge')}</p>
        <h2>{t('rgpd.title3')}</h2>
        <p style={{ whiteSpace: 'pre-line' }}>{t('rgpd.rights')}</p>
        <h2>{t('rgpd.title4')}</h2>
        <p style={{ whiteSpace: 'pre-line' }}>{t('rgpd.updates')}</p>
      </div>
    </Layout>
  );
};

export default withTrans(RGPD);
